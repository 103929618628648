import React from 'react';
import classNames from 'classnames';

import * as configApi from 'common/core/configurations';
import * as frontendApi from 'common/frontend/internal';
import { showInfoToastNow } from 'common/components/ToastNotification/Toastmaster';
import GatedIcon from 'common/components/GatedIcon';
import GateableButton from 'common/components/GateableButton';
import { Modal, ModalContent, ModalFooter } from 'common/components/Modal';
import Button from 'common/components/Button';
import { modulesToShowConfirmDialog, moduleConfig, disableModuleAction } from '../ModuleConfigConstants';

class ModuleRow extends React.Component {
  state = {
    moduleEnabled: this.props.enabled,
    dialogVisible: false,
    currentModuleMessage: ''
  };

  showDialog = () => {
    this.setState({ dialogVisible: true });
  }

  handleDialogDismiss = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ dialogVisible: false });
  };

  toggleEnabled = async () => {
    const {
      name
    } = this.props;

    if (!this.state.moduleEnabled) {
      this.doToggle(); // If we're going from disabled to enabled, we never show dialog warning
    } else if (!modulesToShowConfirmDialog.includes(name)) {
      this.doToggle(); // If going from enabled to disabled and is NOT in our list of special modules, toggle
    } else {
      this.setState({ currentModuleMessage: moduleConfig[name].moduleMessage });
      this.showDialog();
    }
  };

  doToggle = async () => {
    const { moduleEnabled } = this.state;
    const {
      name,
      featureSetConfigId,
    } = this.props;
    const domain = window.socrata.targetCname;

    try {
      await configApi.updateConfigProperty(featureSetConfigId, name, !moduleEnabled);
      showInfoToastNow(`${moduleEnabled ? 'Disabled' : 'Enabled'} module '${name}'.`);
      this.setState({ moduleEnabled: !moduleEnabled });
      if (modulesToShowConfirmDialog.includes(name) && !this.state.moduleEnabled) {
        await disableModuleAction(name);
        this.handleDialogDismiss();
      }
      await frontendApi.flushCache(domain);
    } catch (err) {
      console.error('toggleEnabled error', err);
    }
  };

  render() {
    const { moduleEnabled, dialogVisible } = this.state;
    const {
      name,
      description,
      gate,
      removeModule
    } = this.props;

    const labelClass = classNames('module-name', { disabled: !moduleEnabled });

    return (
      <div>
        <li className="module-row">
          <div className={labelClass}>
            <h6>{name}{moduleEnabled || ' (disabled)'}</h6>
            <p>{description}</p>
          </div>
          <div className="module-actions">
            {gate && <GatedIcon gate={gate} />}
            <GateableButton gate={gate} onClick={this.toggleEnabled}>
              {moduleEnabled ? 'Disable' : 'Enable'}
            </GateableButton>
            <GateableButton gate={gate} onClick={() => removeModule(name)}>
              Remove
            </GateableButton>
          </div>
        </li>
        {dialogVisible &&
          <Modal onDismiss={this.handleDialogDismiss}>
            <ModalContent>
              {this.state.currentModuleMessage}
            </ModalContent>
            <ModalFooter>
              <Button
                type="submit"
                onClick={this.handleDialogDismiss}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={this.doToggle}
              >
                Continue
              </Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    );
  }
}

export default ModuleRow;
